import { useCallback, useState } from 'react';
import { PageWithLayout } from '@/types/AppWithLayout';
import CaireHead from '@/components/CaireHead';
import { Alert } from '@mantine/core';
import CognitoAuthenticator from '@/components/CognitoAuthenticator';
import {
  confirmSignUp,
  ConfirmSignUpInput,
  signUp,
  SignUpInput,
} from 'aws-amplify/auth';
import { AuthenticatorProvider } from '@aws-amplify/ui-react-core';
import { AuthenticatorProps } from '@aws-amplify/ui-react';
import AuthLayout from '@/components/layouts/AuthLayout';
import { useCognitoSession } from '@/contexts/Cognito';
import { useRouter } from 'next/router';

const FORM_FIELDS: AuthenticatorProps['formFields'] = {
  signUp: {
    email: {
      label: 'Email',
      placeholder: '',
      required: true,
      order: 1,
      type: 'email',
    },
    password: {
      placeholder: '',
      required: true,
    },
    confirm_password: {
      placeholder: '',
      required: true,
    },
  },
};

type SignInPhase = 'default' | 'didRegister';

// TODO: CRE-3108 Move login UI into separate phase routes within /auth/login|signup|link
const LoginWelcome: PageWithLayout = () => {
  const [signInPhase, setSignInPhase] = useState<SignInPhase>('default');
  const router = useRouter();

  const { authenticated } = useCognitoSession();

  if (authenticated) {
    router.replace('/auth/bypass');
  }

  const handleSignUp = async (input: SignUpInput) => {
    const { username, password, options } = input;
    const user = await signUp({
      username,
      password,
      options: {
        autoSignIn: false, // can't auto-sign in when manual confirmation code is required
        userAttributes: {
          ...(options?.userAttributes ?? {}),
          email: username,
        },
      },
    });
    return user;
  };

  const handleConfirmSignUp = useCallback(
    async (input: ConfirmSignUpInput) => {
      const confirmed = await confirmSignUp(input);

      // Assume we are confirmed, but not yet logged in? Ergo, set state?
      setSignInPhase('didRegister');

      return confirmed;
    },
    [setSignInPhase]
  );

  // The Authenticator SDK component has state quirks where it will simply disappear from
  // the render if props change dramatically. Therefore we make the context provider unique
  // based on the registration mode we want to display.
  if (signInPhase === 'didRegister') {
    return (
      <>
        <CaireHead title="Complete Registration - Login" />
        <AuthenticatorProvider key="auth.contextAfterRegistration">
          {/* If we registered, we now have to log in. Show the form without sign up option. */}
          <Alert variant="success">
            OK! You&apos;ve confirmed your email address. Now please log in to
            get started.
          </Alert>
          <CognitoAuthenticator
            key={'auth.afterSignUp'}
            initialState="signIn"
            loginMechanisms={['email']}
            hideSignUp
            formFields={FORM_FIELDS}
          />
        </AuthenticatorProvider>
      </>
    );
  }

  // By default, we allow users to tab across to create a Cognito account. The credentials
  // will need to match an existing staff user in the database in order to be linked.
  return (
    <>
      <CaireHead title="Login" />
      <AuthenticatorProvider key="auth.contextDefault">
        <CognitoAuthenticator
          key={'auth.regular'}
          services={{
            handleSignUp,
            handleConfirmSignUp,
          }}
          initialState="signIn"
          loginMechanisms={['email']}
          signUpAttributes={[]}
          formFields={FORM_FIELDS}
        />
      </AuthenticatorProvider>
    </>
  );
};

LoginWelcome.getLayout = (page) => <AuthLayout>{page}</AuthLayout>;

export default LoginWelcome;
